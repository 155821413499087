import {
  GET_USER,
  NO_USER_FOUND,
  USER_IS_LOADING,
  SET_CONVERSATIONS,
  SET_SUBSCRIBED_ON_CONVERSATION,
  SET_GROUP_CONVERSATIONS,
  PROGRESS_UPLOADING_PERCENTAGE,
} from '../actions/types';
import {
  Conversation,
  ConversationInsert,
  Message,
  MessageInsert,
  SenderType,
  Student,
  StudentconversationInsert,
  User,
  Userconversation,
  UserconversationInsert,
} from '../../graphql/API';
import {
  createConversation,
  createStudentconversation,
  sendMessage,
} from '../../graphql/mutations';

import {me, messagesByConversationId} from './CustomQuery';
import {
  createMessage,
  createUserconversation,
  deleteUserconversation,
  subscribeDeleteUCs,
  subscribeToNewMessage,
  subscribeToNewUCs,
} from './CustomSubscription';
import moment from 'moment-timezone';
import {updateAdmin} from '../../modules/Accounts/AccountsQueries';
import {generateClient, GraphQLResult} from 'aws-amplify/api';
import {signIn, fetchUserAttributes} from 'aws-amplify/auth';

const client = generateClient();
export const setUserConversations = (
  userConversations: Array<Userconversation | null>,
) => {
  return {type: SET_CONVERSATIONS, payload: userConversations};
};

export const setUploadingProgress = (uploadingProgress: number) => {
  console.log('UPLADETEEEE', uploadingProgress);
  return {type: PROGRESS_UPLOADING_PERCENTAGE, payload: uploadingProgress};
};

export const setGroupConversations = (
  conversations: Array<Conversation | null>,
) => {
  return {type: SET_GROUP_CONVERSATIONS, payload: conversations};
};

export const setSubscribedConversationIds = (
  subscribedConversationIds: string[],
) => {
  return {
    type: SET_SUBSCRIBED_ON_CONVERSATION,
    payload: subscribedConversationIds,
  };
};

export const setLastSeenOnline = () => {
  return async (dispatch, getState: any) => {
    let user = getState().user.user;
    console.log('Setting last seen online', user);
    if (user?.admin?.id) {
      const adminResp: GraphQLResult<any> = await client.graphql({
        query: updateAdmin,
        variables: {
          id: user?.admin?.id,
          updatedAt: user?.admin.updatedAt,
          input: {
            lastSeenOnline: moment().utc().format(),
          },
        },
      });

      console.log('user.user', user);
      let userModify = {
        ...user,
        admin: adminResp?.data?.updateAdmin,
      };
      console.log('userModify', userModify);
      dispatch({
        type: GET_USER,
        payload: userModify,
      });
    }
  };
};

export const doSignIn =
  (email: string, password: string) => async (dispatch: any, getState: any) => {
    dispatch({type: USER_IS_LOADING, payload: true});
    try {
      await signIn({
        username: email,
        password: password,
        options: {
          clientMetadata: {
            typeLogin: 'Admins',
          },
        },
      });
      dispatch({type: USER_IS_LOADING, payload: false});

      dispatch(getMe());
      // const authData = await fetchUserAttributes();
      // dispatch(getUser());

      // dispatch({type: SIGN_IN_SUCCESS, payload: authData});
      //browserHistory.push("/home")
    } catch (error: any) {
      console.log('SIGN_IN_ERROR1', error?.message);
      dispatch(isLoadingUser(false));
      alert(
        error?.message?.includes('Not Authorized')
          ? ' You are not authorized to login in'
          : error,
      );
    }
  };

export const getMe = () => {
  return async (dispatch: any, getState: any) => {
    console.log('ENTER_GET_USER');
    dispatch({type: USER_IS_LOADING, payload: true});

    try {
      let userResponse: GraphQLResult<any> = await client.graphql({
        query: me,
        variables: {},
      });

      const conversations = getState().user.conversations;
      const user = userResponse.data.me;
      if (user) {
        dispatch({type: GET_USER, payload: user});
        console.log('SSDDSD', user);
        if (conversations.length > 0) {
          const instructorConversations = user?.userconversations?.map(
            (instructorConv: Userconversation) => {
              const conversation = conversations.find(
                (_adminCon: Userconversation) =>
                  _adminCon.id === instructorConv.id,
              );
              if (conversation) {
                return conversation;
              } else {
                return instructorConv;
              }
            },
          );
          dispatch(setUserConversations([...instructorConversations] ?? []));
        } else {
          dispatch(setUserConversations(user?.userconversations ?? []));
        }

        console.log('conversations?.length === 0', user);
        dispatch({type: USER_IS_LOADING, payload: false});
        dispatch(subscribeDeleteUserConversation());
        dispatch(subscribeToNewMessages());
        console.log('CURRENT_USER', user);
      } else {
        console.log('ERROR_NO_USER_FOUND');
        dispatch({type: NO_USER_FOUND});
      }
    } catch (error) {
      console.log('ERROR_GET_USER', error);
      dispatch({type: USER_IS_LOADING, payload: false});
      // dispatch(doSignOut());
    }
  };
};

export const resetUser = () => async (dispatch: any) => {
  try {
    dispatch({type: 'RESET_USER'});
  } catch (error) {
    console.log('reset user-> ', error);
  }
  // dispatch(getUser());
};

export const isLoadingUser = (isLoading: boolean) => {
  return {
    type: USER_IS_LOADING,
    payload: isLoading,
  };
};

export const fetchMessagesByConversationId = (
  conversationId: string,
  isFetchMore: boolean | null = true,
) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch(isLoadingUser(true));
      console.log('_next_Token', isFetchMore);

      const conversations = getState().user.conversations;

      const selectedUsrConversationIndex = conversations.findIndex(
        (usrConv: Userconversation) =>
          usrConv.conversation.id === conversationId,
      );

      const conversation =
        conversations[selectedUsrConversationIndex].conversation;

      const _temp_next_token =
        conversations[selectedUsrConversationIndex]?.nextToken;

      let nextToken: string | null =
        _temp_next_token && isFetchMore ? _temp_next_token : null;

      const scannedCount: number = 20;

      let conversationMessResp: GraphQLResult<any> = await client.graphql({
        query: messagesByConversationId,
        variables: {
          conversationId,
          nextToken,
          scannedCount,
        },
      });

      const conversationMessages =
        conversationMessResp.data?.messagesByConversationId;

      const _tempStdConversations: Array<Userconversation> = [...conversations];
      const oldMessages =
        conversation?.messages && nextToken ? conversation?.messages : [];

      //@ts-ignore
      _tempStdConversations[selectedUsrConversationIndex].nextToken =
        conversationMessages.nextToken;

      _tempStdConversations[selectedUsrConversationIndex].conversation = {
        ...conversation,
        messages: [...oldMessages, ...conversationMessages.items],
      };

      dispatch(setUserConversations(_tempStdConversations));
      dispatch(isLoadingUser(false));
    } catch (error: any) {
      dispatch(isLoadingUser(false));
      console.log('SDerrorSDselectedConversation', error);
    }
  };
};

export const fetchMessagesBygroupConversationId = (
  groupConversationId: string,
  isFetchMore: boolean | null = true,
) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch(isLoadingUser(true));
      console.log('_next_Token', isFetchMore);

      const groupConversations = getState().user.groupConversations;

      const selectedConversationIndex = groupConversations.findIndex(
        (conversa: Userconversation) => conversa.id === groupConversationId,
      );

      const conversation = groupConversations[selectedConversationIndex];

      const _temp_next_token =
        groupConversations[selectedConversationIndex]?.nextToken;

      let nextToken: string | null =
        _temp_next_token && isFetchMore ? _temp_next_token : null;

      const scannedCount: number = 20;

      let conversationMessResp: GraphQLResult<any> = await client.graphql({
        query: messagesByConversationId,
        variables: {
          conversationId: groupConversationId,
          nextToken,
          scannedCount,
        },
      });

      const conversationMessages =
        conversationMessResp.data?.messagesByConversationId;

      const _tempStdConversations: Array<Conversation> = [
        ...groupConversations,
      ];

      const oldMessages =
        conversation?.messages && nextToken ? conversation?.messages : [];

      //@ts-ignore
      _tempStdConversations[selectedConversationIndex].nextToken =
        conversationMessages.nextToken;

      _tempStdConversations[selectedConversationIndex] = {
        ...conversation,
        messages: [...oldMessages, ...conversationMessages.items],
      };

      dispatch(setGroupConversations(_tempStdConversations));
      dispatch(isLoadingUser(false));
    } catch (error: any) {
      dispatch(isLoadingUser(false));
      console.log('SDerrorSDselectedConversation', error);
    }
  };
};

function subscribeToNewMessages(): any {
  return async (dispatch: any, getState: any) => {
    try {
      const usrConversations: Array<Userconversation> =
        getState().user.conversations;

      usrConversations.map(async (usrConv: Userconversation) => {
        const subscribedConversationIds: Array<string> =
          getState().user.subscribedConversationIds;

        if (
          subscribedConversationIds &&
          !subscribedConversationIds.includes(usrConv.conversation.id)
        ) {
          let newSubscribedConversationIds = [...subscribedConversationIds];
          newSubscribedConversationIds.push(usrConv.conversation.id);
          dispatch(
            setSubscribedConversationIds([...newSubscribedConversationIds]),
          );

          let subscribedConversation: GraphQLResult<any> = await client
            .graphql({
              query: subscribeToNewMessage,
              variables: {
                conversationId: usrConv?.conversation?.id,
              },
            })
            //@ts-ignore
            ?.subscribe({
              next: ({data}: any) => {
                console.log('SET_CONVERSATIONS', data);
                const message: Message = data.subscribeToNewMessage;
                const usrConversations: Array<Userconversation> =
                  getState().user.conversations;

                const conversationIndexRelatedToMessage: number =
                  usrConversations.findIndex(
                    (usrConv: Userconversation) =>
                      usrConv?.conversation?.id === message?.conversationId,
                  );

                let newUsrConversation = [...usrConversations];
                if (conversationIndexRelatedToMessage !== -1) {
                  newUsrConversation[
                    conversationIndexRelatedToMessage
                  ].conversation.messages?.unshift(message);

                  dispatch(setUserConversations([...newUsrConversation]));
                } else {
                  console.log('UnsubscribedConversation');

                  // @ts-ignore
                  subscribedConversation?.unsubscribe();
                }
              },
              error: (error: any) => {
                // subscribedConversation.unsubscribe();
                if (process.env.NODE_ENV !== 'development') {
                  window.location.reload();
                  console.warn(error);
                }
              },
            });
        }
      });
    } catch (error: any) {
      console.log('SDerrorSDsubscription', error);
    }
  };
}

export function subscribeToNewGroupMessages(): any {
  return async (dispatch: any, getState: any) => {
    try {
      const groupConversations: Array<Conversation> =
        getState().user.groupConversations;

      groupConversations.map(async (convers: Conversation) => {
        const subscribedConversationIds: Array<string> =
          getState().user.subscribedConversationIds;

        if (
          subscribedConversationIds &&
          !subscribedConversationIds.includes(convers.id)
        ) {
          let newSubscribedConversationIds = [...subscribedConversationIds];
          newSubscribedConversationIds.push(convers.id);
          dispatch(
            setSubscribedConversationIds([...newSubscribedConversationIds]),
          );

          let subscribedConversation: GraphQLResult<any> = await client.graphql(
            {
              query: subscribeToNewMessage,
              variables: {
                conversationId: convers?.id,
              },
            },
          );

          //@ts-ignore
          let unSubscribe = subscribedConversation.subscribe({
            next: ({data}: any) => {
              console.log('SET_CONVERSATIONS', data);
              const message: Message = data.subscribeToNewMessage;
              const grpConversations: Array<Conversation> =
                getState().user.groupConversations;

              const conversationIndexRelatedToMessage: number =
                grpConversations.findIndex(
                  (conv: Conversation) => conv?.id === message?.conversationId,
                );

              let newGrpConversation = [...grpConversations];
              if (conversationIndexRelatedToMessage !== -1) {
                newGrpConversation[
                  conversationIndexRelatedToMessage
                ].messages?.unshift(message);

                dispatch(setGroupConversations([...newGrpConversation]));
              } else {
                console.log('UnsubscribedConversation');
                const subscribedConversaIds: Array<string> =
                  getState().user.subscribedConversationIds;

                const subscribedConversationIdIndex =
                  subscribedConversaIds.findIndex(
                    (subscribedConversationId: string) =>
                      subscribedConversationId === message?.conversationId,
                  );

                if (subscribedConversationIdIndex !== -1) {
                  let newSubscribedConversationIds = [...subscribedConversaIds];
                  newSubscribedConversationIds.splice(
                    subscribedConversationIdIndex,
                    1,
                  );
                  dispatch(
                    setSubscribedConversationIds([
                      ...newSubscribedConversationIds,
                    ]),
                  );
                }
                //@ts-ignore
                unSubscribe.unsubscribe();
              }
            },
            error: (error: any) => {
              if (process.env.NODE_ENV !== 'development') {
                window.location.reload();
                console.warn(error);
              }
            },
          });
        }
      });
    } catch (error: any) {
      console.log('SDerrorSDsubscription', error);
    }
  };
}

export const createUserMessage = (
  content: string,
  conversationId: string,
  endPointArn: string,
) => {
  return async (dispatch: any, getState: any) => {
    try {
      const userId = getState().user.user.id;

      const message: MessageInsert = {
        conversationId,
        content: content,
        senderType: SenderType.USER,
        userId,
      };

      const sendNotification: any = {
        title: 'PikaDo Team',
        body: content,
        targetArn: endPointArn,
        conversationId,
      };

      await client.graphql({
        query: createMessage,
        variables: {
          input: message,
        },
      });

      if (endPointArn) {
        await client.graphql({
          query: sendMessage,
          variables: {
            ...sendNotification,
          },
        });
      }
    } catch (error: any) {
      console.log('ERROR_CHREAETING_MESSAGE_createUserMessage', error);
      // dispatch(isLoadingStudentState(false, error));
    }
  };
};

export const endChat = (userConversationId: string, conversationId: string) => {
  return async (dispatch: any, getState: any) => {
    try {
      let adminConversations: Array<Userconversation> =
        getState().user.conversations;

      let subscribedConversationIds: string[] =
        getState().user.subscribedConversationIds;

      await client.graphql({
        query: deleteUserconversation,
        variables: {
          id: userConversationId,
        },
      });

      const conversationIndex = adminConversations.findIndex(
        (adminConv: Userconversation) =>
          adminConv.conversation.id === conversationId,
      );

      let newAdminCon = [...adminConversations];

      newAdminCon.splice(conversationIndex, 1);

      const subscribedIndex = subscribedConversationIds.findIndex(
        (subscribedConversationId: string) =>
          subscribedConversationId === conversationId,
      );

      if (subscribedIndex !== -1) {
        let newSubscribedConversationIds = [...subscribedConversationIds];
        newSubscribedConversationIds.splice(subscribedIndex, 1);
        dispatch(
          setSubscribedConversationIds([...newSubscribedConversationIds]),
        );
      }

      dispatch(setUserConversations([...newAdminCon]));
    } catch (error: any) {
      console.log('ERROR_CHREAETING_MESSAGE_endChat', error);
      // dispatch(isLoadingStudentState(false, error));
    }
  };
};

// after admin accept Chat remove all admins from Chat
export const acceptChat = (
  UsersConversation: Userconversation[],
  conversationId: string,
) => {
  return async (dispatch: any, getState: any) => {
    try {
      // current logged in admin's user
      const userId = getState().user.user.id;
      await Promise.all(
        // map all added users in this conversation to remove all the admins in the chat and leave only the current logged in admin in the chat.
        UsersConversation.map(async (usrConv: Userconversation, index) => {
          console.log('usrConv?.user->', usrConv?.user);

          //  Delete all the users in the chat if their id doesn't match the current logged in admin
          // if ( "amro" !== "mena")
          if (usrConv?.user?.id !== userId) {
            // If the user is admin, then delete him. This check is added to avoid removing the preinstructors and instructors
            if (usrConv?.user?.admin) {
              const usrConversations: Array<Userconversation> =
                getState().user.conversations;
              let adminUsrConversations = [...usrConversations];

              const conversationIndex = adminUsrConversations.findIndex(
                (adminConver: Userconversation) =>
                  adminConver.conversation.id === conversationId,
              );

              const filteredUsrInConversation = usrConversations[
                conversationIndex
              ].conversation.userconversations.filter(
                (usrInCon) => usrInCon.id !== usrConv.id,
              );

              adminUsrConversations[
                conversationIndex
              ].conversation.userconversations = filteredUsrInConversation;

              await client.graphql({
                query: deleteUserconversation,
                variables: {
                  id: usrConv.id,
                },
              });

              dispatch(setUserConversations([...adminUsrConversations]));
            }
          } else {
            console.log(
              'usrConv.conversation.userconversations->',
              usrConv.conversation.userconversations,
            );
          }
        }),
      );
      await dispatch(
        fetchMessagesByConversationId(
          UsersConversation[0].conversation.id,
          null,
        ),
      );
    } catch (error: any) {
      console.log('ERROR_CHREAETING_MESSAGE_acceptChat', error);
      dispatch(getMe());
      // dispatch(isLoadingStudentState(false, error));
    }
  };
};

export const subscribeToNewConversation = () => {
  return async (dispatch: any, getState: any) => {
    try {
      const subscribedNewUcs = await client
        .graphql({
          query: subscribeToNewUCs,
          variables: {},
        })
        //@ts-ignore
        ?.subscribe({
          next: ({data}: any) => {
            console.log('newSubscribedUsrConv', data);

            const newSubscribedUsrConv: Userconversation =
              data.subscribeToNewUCs;

            const currentUser: User = getState().user.user;

            if (currentUser.id === newSubscribedUsrConv?.user?.id) {
              // createLocalNotification(message);
              setTimeout(() => {
                dispatch(getMe());
              }, 1200);
            }
          },
          error: (error: any) => {
            console.warn(error);
            subscribedNewUcs.unsubscribe();
          },
        });
    } catch (error: any) {
      console.log('ERROR_SUBSCR)BE_YS', error);
      // dispatch(isLoadingStudentState(false, error));
    }
  };
};

export function subscribeDeleteUserConversation(): any {
  return async (dispatch: any, getState: any) => {
    try {
      const adminConversations: Array<Userconversation> =
        getState().user.conversations;
      const subscribedConversationIds: Array<string> =
        getState().user.subscribedConversationIds;

      adminConversations.map(async (usrConver: Userconversation) => {
        console.log('DELETE_SUBSSCRRRUUU_CHAT', usrConver);
        if (!subscribedConversationIds.includes(usrConver.conversation.id)) {
          const subscribedDeleteUcs = client
            .graphql({
              query: subscribeDeleteUCs,
              variables: {
                id: usrConver.id,
              },
            })
            //@ts-ignore
            ?.subscribe({
              next: ({data}: any) => {
                const subscribedConversationIds: Array<string> =
                  getState().user.subscribedConversationIds;

                console.log('deletetetete', data);
                // const deletedUc: Userconversation = data.subscribeDeleteUCs;
                // const currentUser: User = getState().user.user;

                const subscribedIndex = subscribedConversationIds.findIndex(
                  (subscribedConversationId: string) =>
                    subscribedConversationId === usrConver.conversation.id,
                );

                if (subscribedIndex !== -1) {
                  let newSubscribedConversationIds = [
                    ...subscribedConversationIds,
                  ];
                  newSubscribedConversationIds.splice(subscribedIndex, 1);
                  dispatch(
                    setSubscribedConversationIds([
                      ...newSubscribedConversationIds,
                    ]),
                  );
                }

                dispatch(getMe());
                // if (deletedUc.user.id === currentUser.id) {
                // }
              },
              error: (error: any) => {
                console.warn(error);
                subscribedDeleteUcs.unsubscribe();
              },
            });
        }
      });
    } catch (error: any) {
      console.log('SDerrorSDsubscription', error);
      if (process.env.NODE_ENV !== 'development') {
        window.location.reload();
        console.warn(error);
      }
    }
  };
}

export const createPikadoConversation = (studentId: string) => {
  return async (dispatch: any, getState: any) => {
    try {
      const createConversat: ConversationInsert = {
        name: 'PikaDo Team',
        isActive: true,
      };

      const conversation: GraphQLResult<any> = await client.graphql({
        query: createConversation,
        variables: {
          input: createConversat,
        },
      });

      const createdConversation: Conversation =
        conversation.data.createConversation;

      const createStudentConversat: StudentconversationInsert = {
        conversationId: createdConversation.id,
        studentId,
      };

      await client.graphql({
        query: createStudentconversation,
        variables: {
          input: createStudentConversat,
        },
      });

      dispatch(createUserOnConversation(createdConversation.id));
    } catch (error: any) {}
  };
};

export const createUserOnConversation = (conversationId: string) => {
  return async (dispatch: any, getState: any) => {
    try {
      const userId = getState().user.user.id;

      const createUsrConversation: UserconversationInsert = {
        userId,
        conversationId,
      };

      await client.graphql({
        query: createUserconversation,
        variables: {
          input: createUsrConversation,
        },
      });
    } catch (error: any) {}
  };
};
