export const courses = /* GraphQL */ `
  query courses(
    $filter: CoursesFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    courses(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        name
        program {
          id
          name
        }
        language {
          id
          language
          country
        }
        level {
          id
          name
        }
        plan
      }
      nextToken
      scannedCount
    }
  }
`;

export const coursesWithoutPlan = /* GraphQL */ `
  query courses(
    $filter: CoursesFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    courses(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        name
        program {
          id
          name
        }
        language {
          id
          language
          country
        }
        level {
          id
          name
        }
        curriculum {
          id
        }
      }
      nextToken
      scannedCount
    }
  }
`;

export const languages = /* GraphQL */ `
  query Languages(
    $filter: LanguagesFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    languages(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        language
        country
      }
      nextToken
      scannedCount
    }
  }
`;

export const createCourse = /* GraphQL */ `
  mutation CreateCourse($input: CourseInsert!) {
    createCourse(input: $input) {
      id
      createdAt
      updatedAt
      name
    }
  }
`;

export const course = /* GraphQL */ `
  query Course($id: String!, $plan: String) {
    course(id: $id, plan: $plan) {
      id
      createdAt
      updatedAt
      name
      program {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
      }
      language {
        id
        createdAt
        updatedAt
        language
        country
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      lessons {
        id
        createdAt
        updatedAt
        name
      }
      videosessions {
        id
        createdAt
        updatedAt
        duration
      }
      curriculum {
        id
        createdAt
        updatedAt
        name
        totalPlanPeriod
      }
    }
  }
`;

export const videosessions = /* GraphQL */ `
  query Videosessions(
    $filter: VideosessionsFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    videosessions(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        duration
        name
        level {
          id
          name
          number
          score
        }
        course {
          id
          name
          level {
            id
            name
            number
            score
          }
          curriculum {
            id
            name
          }
        }
        test {
          id
          type
          name
          name_i18n
        }
      }
      nextToken
      scannedCount
    }
  }
`;

export const videosession = /* GraphQL */ `
  query Videosession($id: ID!) {
    videosession(id: $id) {
      id
      createdAt
      updatedAt
      isActive
      level {
        id
        name
        number
        score
      }
      curriculum {
        id
        name
      }
      course {
        id
        name
        level {
          id
          name
        }
        curriculum {
          id
          name
        }
      }
      test {
        id
        type
        name
        name_i18n
      }
      duration
      name
    }
  }
`;
