/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const studentaudioprofile = /* GraphQL */ `query Studentaudioprofile($id: ID!) {
  studentaudioprofile(id: $id) {
    id
    createdAt
    updatedAt
    introAIVSUri
    sentenceIntro
    correctAIVSUri
    sentenceCorrect
    failAIVSUri
    sentenceFail
    finalAIVSUri
    sentenceFinal
    studentName
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.StudentaudioprofileQueryVariables,
  APITypes.StudentaudioprofileQuery
>;
export const getSubscriptionsByDiscountCode = /* GraphQL */ `query GetSubscriptionsByDiscountCode($discountcodeId: String!) {
  getSubscriptionsByDiscountCode(discountcodeId: $discountcodeId) {
    numOfSubscriptions
    numOfUsage
    discountcode {
      id
      createdAt
      updatedAt
      codeName
      valuePercentage
      isExpired
      validFrom
      expirationDate
      usage
      numOfUsage
      partnerName
      __typename
    }
    school {
      id
      createdAt
      updatedAt
      nameArabic
      name
      isActive
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSubscriptionsByDiscountCodeQueryVariables,
  APITypes.GetSubscriptionsByDiscountCodeQuery
>;
export const studentaiVideoSessionsByMembershipId = /* GraphQL */ `query StudentaiVideoSessionsByMembershipId($membershipId: String!) {
  studentaiVideoSessionsByMembershipId(membershipId: $membershipId) {
    items {
      id
      createdAt
      updatedAt
      status
      currentQuestionIndex
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.StudentaiVideoSessionsByMembershipIdQueryVariables,
  APITypes.StudentaiVideoSessionsByMembershipIdQuery
>;
export const studentaisession = /* GraphQL */ `query Studentaisession($id: ID!) {
  studentaisession(id: $id) {
    id
    createdAt
    updatedAt
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    curriculum {
      id
      createdAt
      updatedAt
      name
      totalPlanPeriod
      __typename
    }
    videosession {
      id
      createdAt
      updatedAt
      duration
      name
      isActive
      __typename
    }
    membership {
      id
      createdAt
      updatedAt
      paymentStatus
      placementStatus
      isActive
      startDate
      endDate
      sessionPerWeek
      isFullFilled
      __typename
    }
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      __typename
    }
    status
    currentQuestionIndex
    __typename
  }
}
` as GeneratedQuery<
  APITypes.StudentaisessionQueryVariables,
  APITypes.StudentaisessionQuery
>;
export const studentaisessions = /* GraphQL */ `query Studentaisessions(
  $filter: StudentaisessionsFilter
  $scannedCount: Int
  $nextToken: String
) {
  studentaisessions(
    filter: $filter
    scannedCount: $scannedCount
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      updatedAt
      status
      currentQuestionIndex
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.StudentaisessionsQueryVariables,
  APITypes.StudentaisessionsQuery
>;
export const videosession = /* GraphQL */ `query Videosession($id: ID!) {
  videosession(id: $id) {
    id
    createdAt
    updatedAt
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    curriculum {
      id
      createdAt
      updatedAt
      name
      totalPlanPeriod
      __typename
    }
    course {
      id
      createdAt
      updatedAt
      name
      plan
      __typename
    }
    test {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    studentaisessions {
      id
      createdAt
      updatedAt
      status
      currentQuestionIndex
      __typename
    }
    duration
    name
    isActive
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VideosessionQueryVariables,
  APITypes.VideosessionQuery
>;
export const videosessions = /* GraphQL */ `query Videosessions(
  $filter: VideosessionsFilter
  $scannedCount: Int
  $nextToken: String
) {
  videosessions(
    filter: $filter
    scannedCount: $scannedCount
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      updatedAt
      duration
      name
      isActive
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VideosessionsQueryVariables,
  APITypes.VideosessionsQuery
>;
export const segment = /* GraphQL */ `query Segment($id: ID!) {
  segment(id: $id) {
    id
    createdAt
    updatedAt
    segmantName
    createdPinpointSegmentId
    __typename
  }
}
` as GeneratedQuery<APITypes.SegmentQueryVariables, APITypes.SegmentQuery>;
export const segments = /* GraphQL */ `query Segments($filter: SegmentFilter, $scannedCount: Int, $nextToken: String) {
  segments(
    filter: $filter
    scannedCount: $scannedCount
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      updatedAt
      segmantName
      createdPinpointSegmentId
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<APITypes.SegmentsQueryVariables, APITypes.SegmentsQuery>;
export const curriculum = /* GraphQL */ `query Curriculum($id: ID!) {
  curriculum(id: $id) {
    id
    createdAt
    updatedAt
    name
    courses {
      id
      createdAt
      updatedAt
      name
      plan
      __typename
    }
    totalPlanPeriod
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CurriculumQueryVariables,
  APITypes.CurriculumQuery
>;
export const curriculums = /* GraphQL */ `query Curriculums(
  $filter: CurriculumsFilter
  $scannedCount: Int
  $nextToken: String
) {
  curriculums(
    filter: $filter
    scannedCount: $scannedCount
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      updatedAt
      name
      totalPlanPeriod
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CurriculumsQueryVariables,
  APITypes.CurriculumsQuery
>;
export const topic = /* GraphQL */ `query Topic($id: ID!) {
  topic(id: $id) {
    id
    createdAt
    updatedAt
    topicName
    topicArn
    __typename
  }
}
` as GeneratedQuery<APITypes.TopicQueryVariables, APITypes.TopicQuery>;
export const topics = /* GraphQL */ `query Topics($filter: TopicFilter, $scannedCount: Int, $nextToken: String) {
  topics(filter: $filter, scannedCount: $scannedCount, nextToken: $nextToken) {
    items {
      id
      createdAt
      updatedAt
      topicName
      topicArn
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<APITypes.TopicsQueryVariables, APITypes.TopicsQuery>;
export const syllable = /* GraphQL */ `query Syllable($id: ID!) {
  syllable(id: $id) {
    id
    createdAt
    updatedAt
    letters
    correctSyllable {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.SyllableQueryVariables, APITypes.SyllableQuery>;
export const syllables = /* GraphQL */ `query Syllables(
  $filter: SyllablesFilter
  $scannedCount: Int
  $nextToken: String
) {
  syllables(
    filter: $filter
    scannedCount: $scannedCount
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      updatedAt
      letters
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<APITypes.SyllablesQueryVariables, APITypes.SyllablesQuery>;
export const teacher = /* GraphQL */ `query Teacher($id: ID!) {
  teacher(id: $id) {
    id
    createdAt
    updatedAt
    name
    userName
    email
    classesschool {
      id
      createdAt
      updatedAt
      name
      schoolYear
      __typename
    }
    school {
      id
      createdAt
      updatedAt
      nameArabic
      name
      isActive
      __typename
    }
    user {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.TeacherQueryVariables, APITypes.TeacherQuery>;
export const teachers = /* GraphQL */ `query Teachers(
  $filter: TeachersFilter
  $scannedCount: Int
  $nextToken: String
) {
  teachers(
    filter: $filter
    scannedCount: $scannedCount
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      updatedAt
      name
      userName
      email
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<APITypes.TeachersQueryVariables, APITypes.TeachersQuery>;
export const classschool = /* GraphQL */ `query Classschool($id: String!) {
  classschool(id: $id) {
    id
    createdAt
    updatedAt
    name
    schoolYear
    students {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      __typename
    }
    school {
      id
      createdAt
      updatedAt
      nameArabic
      name
      isActive
      __typename
    }
    teacher {
      id
      createdAt
      updatedAt
      name
      userName
      email
      __typename
    }
    statistic {
      id
      createdAt
      updatedAt
      scores
      averageScore
      fullReportLastGeneratedDate
      phoneticsAsString
      syllablesAsString
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ClassschoolQueryVariables,
  APITypes.ClassschoolQuery
>;
export const classesschool = /* GraphQL */ `query Classesschool(
  $filter: ClassesFilter
  $scannedCount: Int
  $nextToken: String
) {
  classesschool(
    filter: $filter
    scannedCount: $scannedCount
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      updatedAt
      name
      schoolYear
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ClassesschoolQueryVariables,
  APITypes.ClassesschoolQuery
>;
export const listCognitoUsers = /* GraphQL */ `query ListCognitoUsers(
  $filter: AdminListFilter
  $scannedCount: Int
  $nextToken: String
) {
  listCognitoUsers(
    filter: $filter
    scannedCount: $scannedCount
    nextToken: $nextToken
  ) {
    Users {
      Username
      UserCreateDate
      UserLastModifiedDate
      Enabled
      UserStatus
      __typename
    }
    PaginationToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCognitoUsersQueryVariables,
  APITypes.ListCognitoUsersQuery
>;
export const listGroupsForUser = /* GraphQL */ `query ListGroupsForUser($userName: String!) {
  listGroupsForUser(userName: $userName) {
    Groups {
      GroupName
      Description
      UserPoolId
      __typename
    }
    NextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGroupsForUserQueryVariables,
  APITypes.ListGroupsForUserQuery
>;
export const adminGetUser = /* GraphQL */ `query AdminGetUser($userName: String!) {
  adminGetUser(userName: $userName) {
    Username
    UserAttributes {
      Name
      Value
      __typename
    }
    UserCreateDate
    UserLastModifiedDate
    Enabled
    UserStatus
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminGetUserQueryVariables,
  APITypes.AdminGetUserQuery
>;
export const reviewquestion = /* GraphQL */ `query Reviewquestion($id: ID!) {
  reviewquestion(id: $id) {
    id
    createdAt
    updatedAt
    question_i18n
    type
    reviewType
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ReviewquestionQueryVariables,
  APITypes.ReviewquestionQuery
>;
export const reviewquestions = /* GraphQL */ `query Reviewquestions(
  $filter: ReviewquestionFilter
  $scannedCount: Int
  $nextToken: String
) {
  reviewquestions(
    filter: $filter
    scannedCount: $scannedCount
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      updatedAt
      question_i18n
      type
      reviewType
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ReviewquestionsQueryVariables,
  APITypes.ReviewquestionsQuery
>;
export const getInstructorAvailableSlots = /* GraphQL */ `query GetInstructorAvailableSlots(
  $userTimeZone: String
  $instructorId: String
) {
  getInstructorAvailableSlots(
    userTimeZone: $userTimeZone
    instructorId: $instructorId
  ) {
    items {
      dayIndex
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInstructorAvailableSlotsQueryVariables,
  APITypes.GetInstructorAvailableSlotsQuery
>;
export const discountcodes = /* GraphQL */ `query Discountcodes(
  $filter: DiscountcodeFilter
  $scannedCount: Int
  $nextToken: String
) {
  discountcodes(
    filter: $filter
    scannedCount: $scannedCount
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      updatedAt
      codeName
      valuePercentage
      isExpired
      validFrom
      expirationDate
      usage
      numOfUsage
      partnerName
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DiscountcodesQueryVariables,
  APITypes.DiscountcodesQuery
>;
export const promocodes = /* GraphQL */ `query Promocodes(
  $filter: PromocodeFilter
  $scannedCount: Int
  $nextToken: String
) {
  promocodes(
    filter: $filter
    scannedCount: $scannedCount
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      updatedAt
      codeName
      isApplied
      sessionPerWeek
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PromocodesQueryVariables,
  APITypes.PromocodesQuery
>;
export const admin = /* GraphQL */ `query Admin($id: ID!) {
  admin(id: $id) {
    id
    createdAt
    updatedAt
    user {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      __typename
    }
    lastSeenOnline
    firstName
    lastName
    address
    SSN
    gender
    isActive
    __typename
  }
}
` as GeneratedQuery<APITypes.AdminQueryVariables, APITypes.AdminQuery>;
export const admins = /* GraphQL */ `query Admins($filter: AdminFilter, $scannedCount: Int, $nextToken: String) {
  admins(filter: $filter, scannedCount: $scannedCount, nextToken: $nextToken) {
    items {
      id
      createdAt
      updatedAt
      lastSeenOnline
      firstName
      lastName
      address
      SSN
      gender
      isActive
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<APITypes.AdminsQueryVariables, APITypes.AdminsQuery>;
export const studentconversation = /* GraphQL */ `query Studentconversation($id: ID!) {
  studentconversation(id: $id) {
    createdAt
    updatedAt
    id
    conversation {
      createdAt
      updatedAt
      id
      name
      isActive
      __typename
    }
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.StudentconversationQueryVariables,
  APITypes.StudentconversationQuery
>;
export const studentconversations = /* GraphQL */ `query Studentconversations(
  $filter: StudentconversationFilter
  $scannedCount: Int
  $nextToken: String
) {
  studentconversations(
    filter: $filter
    scannedCount: $scannedCount
    nextToken: $nextToken
  ) {
    items {
      createdAt
      updatedAt
      id
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.StudentconversationsQueryVariables,
  APITypes.StudentconversationsQuery
>;
export const userconversation = /* GraphQL */ `query Userconversation($id: ID!) {
  userconversation(id: $id) {
    createdAt
    updatedAt
    id
    conversation {
      createdAt
      updatedAt
      id
      name
      isActive
      __typename
    }
    user {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserconversationQueryVariables,
  APITypes.UserconversationQuery
>;
export const userconversations = /* GraphQL */ `query Userconversations(
  $filter: UserconversationFilter
  $scannedCount: Int
  $nextToken: String
) {
  userconversations(
    filter: $filter
    scannedCount: $scannedCount
    nextToken: $nextToken
  ) {
    items {
      createdAt
      updatedAt
      id
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserconversationsQueryVariables,
  APITypes.UserconversationsQuery
>;
export const messagesByConversationId = /* GraphQL */ `query MessagesByConversationId(
  $conversationId: ID!
  $scannedCount: Int
  $nextToken: String
) {
  messagesByConversationId(
    conversationId: $conversationId
    scannedCount: $scannedCount
    nextToken: $nextToken
  ) {
    items {
      content
      createdAt
      updatedAt
      id
      isSent
      senderType
      conversationId
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MessagesByConversationIdQueryVariables,
  APITypes.MessagesByConversationIdQuery
>;
export const conversation = /* GraphQL */ `query Conversation($id: ID!, $scannedCount: Int) {
  conversation(id: $id, scannedCount: $scannedCount) {
    createdAt
    updatedAt
    id
    name
    isActive
    messages {
      content
      createdAt
      updatedAt
      id
      isSent
      senderType
      conversationId
      __typename
    }
    group {
      id
      createdAt
      updatedAt
      startDate
      name
      division
      limit
      isGroupHasInstructor
      currentSessionNumber
      isActive
      __typename
    }
    userconversations {
      createdAt
      updatedAt
      id
      __typename
    }
    studentconversations {
      createdAt
      updatedAt
      id
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ConversationQueryVariables,
  APITypes.ConversationQuery
>;
export const conversations = /* GraphQL */ `query Conversations(
  $filter: ConversationFilter
  $scannedCount: Int
  $nextToken: String
) {
  conversations(
    filter: $filter
    scannedCount: $scannedCount
    nextToken: $nextToken
  ) {
    items {
      createdAt
      updatedAt
      id
      name
      isActive
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ConversationsQueryVariables,
  APITypes.ConversationsQuery
>;
export const message = /* GraphQL */ `query Message($id: ID!) {
  message(id: $id) {
    content
    createdAt
    updatedAt
    id
    isSent
    senderType
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      __typename
    }
    user {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      __typename
    }
    conversationId
    __typename
  }
}
` as GeneratedQuery<APITypes.MessageQueryVariables, APITypes.MessageQuery>;
export const messages = /* GraphQL */ `query Messages($filter: MessageFilter, $scannedCount: Int, $nextToken: String) {
  messages(
    filter: $filter
    scannedCount: $scannedCount
    nextToken: $nextToken
  ) {
    items {
      content
      createdAt
      updatedAt
      id
      isSent
      senderType
      conversationId
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<APITypes.MessagesQueryVariables, APITypes.MessagesQuery>;
export const review = /* GraphQL */ `query Review($id: String!) {
  review(id: $id) {
    id
    createdAt
    updatedAt
    reviewType
    instructor {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      __typename
    }
    session {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    feedbackquestions {
      id
      createdAt
      updatedAt
      stars
      __typename
    }
    comments
    averageRating
    __typename
  }
}
` as GeneratedQuery<APITypes.ReviewQueryVariables, APITypes.ReviewQuery>;
export const reviews = /* GraphQL */ `query Reviews($filter: ReviewFilter, $scannedCount: Int, $nextToken: String) {
  reviews(filter: $filter, scannedCount: $scannedCount, nextToken: $nextToken) {
    items {
      id
      createdAt
      updatedAt
      reviewType
      comments
      averageRating
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<APITypes.ReviewsQueryVariables, APITypes.ReviewsQuery>;
export const answer = /* GraphQL */ `query Answer($id: String!) {
  answer(id: $id) {
    id
    createdAt
    updatedAt
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      __typename
    }
    question {
      id
      createdAt
      updatedAt
      name
      name_i18n
      videoAISessionUri
      choices
      customChoices
      programType
      keywords
      summary
      summary_i18n
      description
      description_i18n
      text
      textAI
      type
      __typename
    }
    enrollment {
      id
      createdAt
      updatedAt
      placementStatus
      paymentStatus
      startDate
      endDate
      selfRating
      __typename
    }
    monthlyReport {
      id
      createdAt
      updatedAt
      month
      average
      mistakes
      attempts
      totalScores
      totalAverage
      absenceDays
      performanceRate
      absenceRate
      numOfStudentsInClass
      d1
      d2
      d3
      d4
      d5
      d6
      d7
      d8
      d9
      d10
      d11
      d12
      d13
      d14
      d15
      d16
      d17
      d18
      d19
      d20
      d21
      d22
      d23
      d24
      d25
      d26
      d27
      d28
      d29
      d30
      d31
      __typename
    }
    choice
    given
    type
    audioUri
    text
    score
    analysis
    analysisChoices
    useEnglishAi
    __typename
  }
}
` as GeneratedQuery<APITypes.AnswerQueryVariables, APITypes.AnswerQuery>;
export const answerByStudentId = /* GraphQL */ `query AnswerByStudentId($studentId: String!) {
  answerByStudentId(studentId: $studentId) {
    id
    createdAt
    updatedAt
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      __typename
    }
    question {
      id
      createdAt
      updatedAt
      name
      name_i18n
      videoAISessionUri
      choices
      customChoices
      programType
      keywords
      summary
      summary_i18n
      description
      description_i18n
      text
      textAI
      type
      __typename
    }
    enrollment {
      id
      createdAt
      updatedAt
      placementStatus
      paymentStatus
      startDate
      endDate
      selfRating
      __typename
    }
    monthlyReport {
      id
      createdAt
      updatedAt
      month
      average
      mistakes
      attempts
      totalScores
      totalAverage
      absenceDays
      performanceRate
      absenceRate
      numOfStudentsInClass
      d1
      d2
      d3
      d4
      d5
      d6
      d7
      d8
      d9
      d10
      d11
      d12
      d13
      d14
      d15
      d16
      d17
      d18
      d19
      d20
      d21
      d22
      d23
      d24
      d25
      d26
      d27
      d28
      d29
      d30
      d31
      __typename
    }
    choice
    given
    type
    audioUri
    text
    score
    analysis
    analysisChoices
    useEnglishAi
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AnswerByStudentIdQueryVariables,
  APITypes.AnswerByStudentIdQuery
>;
export const availability = /* GraphQL */ `query Availability($id: String!) {
  availability(id: $id) {
    id
    createdAt
    updatedAt
    instructor {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    startTime
    endTime
    slots {
      id
      createdAt
      updatedAt
      name
      slotDuration
      startTime
      endTime
      status
      limit
      current
      day
      __typename
    }
    day
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AvailabilityQueryVariables,
  APITypes.AvailabilityQuery
>;
export const availabilities = /* GraphQL */ `query Availabilities(
  $filter: AvailabilitiesFilter
  $scannedCount: Int
  $nextToken: String
) {
  availabilities(
    filter: $filter
    scannedCount: $scannedCount
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      updatedAt
      startTime
      endTime
      day
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AvailabilitiesQueryVariables,
  APITypes.AvailabilitiesQuery
>;
export const course = /* GraphQL */ `query Course($id: String!, $plan: String) {
  course(id: $id, plan: $plan) {
    id
    createdAt
    updatedAt
    name
    program {
      id
      createdAt
      updatedAt
      name
      name_i18n
      flag
      supportAi
      isActive
      __typename
    }
    language {
      id
      createdAt
      updatedAt
      language
      country
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    plan
    lessons {
      id
      createdAt
      updatedAt
      name
      __typename
    }
    videosessions {
      id
      createdAt
      updatedAt
      duration
      name
      isActive
      __typename
    }
    curriculum {
      id
      createdAt
      updatedAt
      name
      totalPlanPeriod
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.CourseQueryVariables, APITypes.CourseQuery>;
export const courseByEnrollmentId = /* GraphQL */ `query CourseByEnrollmentId($enrollmentId: String!, $plan: String) {
  courseByEnrollmentId(enrollmentId: $enrollmentId, plan: $plan) {
    id
    createdAt
    updatedAt
    name
    program {
      id
      createdAt
      updatedAt
      name
      name_i18n
      flag
      supportAi
      isActive
      __typename
    }
    language {
      id
      createdAt
      updatedAt
      language
      country
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    plan
    lessons {
      id
      createdAt
      updatedAt
      name
      __typename
    }
    videosessions {
      id
      createdAt
      updatedAt
      duration
      name
      isActive
      __typename
    }
    curriculum {
      id
      createdAt
      updatedAt
      name
      totalPlanPeriod
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CourseByEnrollmentIdQueryVariables,
  APITypes.CourseByEnrollmentIdQuery
>;
export const courses = /* GraphQL */ `query Courses($filter: CoursesFilter, $scannedCount: Int, $nextToken: String) {
  courses(filter: $filter, scannedCount: $scannedCount, nextToken: $nextToken) {
    items {
      id
      createdAt
      updatedAt
      name
      plan
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<APITypes.CoursesQueryVariables, APITypes.CoursesQuery>;
export const enrollment = /* GraphQL */ `query Enrollment($id: String!) {
  enrollment(id: $id) {
    id
    createdAt
    updatedAt
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      __typename
    }
    package {
      id
      createdAt
      updatedAt
      number
      onlyAI
      name
      name_i18n
      sessionDuration
      description
      benefits
      benefits_i18n
      details
      details_i18n
      isFree
      numberOfSessions
      description_i18n
      isNative
      isActive
      isHidden
      productIAP
      typePackage
      packageDuration
      discountDescription
      isRecommended
      sessionPerWeek
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    test {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    placementStatus
    paymentStatus
    startDate
    endDate
    slots {
      id
      createdAt
      updatedAt
      name
      slotDuration
      startTime
      endTime
      status
      limit
      current
      day
      __typename
    }
    answer {
      id
      createdAt
      updatedAt
      choice
      given
      type
      audioUri
      text
      score
      analysis
      analysisChoices
      useEnglishAi
      __typename
    }
    selfRating
    curriculum {
      id
      createdAt
      updatedAt
      name
      totalPlanPeriod
      __typename
    }
    discountcode {
      id
      createdAt
      updatedAt
      codeName
      valuePercentage
      isExpired
      validFrom
      expirationDate
      usage
      numOfUsage
      partnerName
      __typename
    }
    promocode {
      id
      createdAt
      updatedAt
      codeName
      isApplied
      sessionPerWeek
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EnrollmentQueryVariables,
  APITypes.EnrollmentQuery
>;
export const enrollments = /* GraphQL */ `query Enrollments(
  $filter: EnrollmentsFilter
  $scannedCount: Int
  $nextToken: String
) {
  enrollments(
    filter: $filter
    scannedCount: $scannedCount
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      updatedAt
      placementStatus
      paymentStatus
      startDate
      endDate
      selfRating
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EnrollmentsQueryVariables,
  APITypes.EnrollmentsQuery
>;
export const group = /* GraphQL */ `query Group($id: String!) {
  group(id: $id) {
    id
    createdAt
    updatedAt
    startDate
    name
    division
    limit
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    memberships {
      id
      createdAt
      updatedAt
      paymentStatus
      placementStatus
      isActive
      startDate
      endDate
      sessionPerWeek
      isFullFilled
      __typename
    }
    sessions {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    instructor {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    isGroupHasInstructor
    program {
      id
      createdAt
      updatedAt
      name
      name_i18n
      flag
      supportAi
      isActive
      __typename
    }
    slot {
      id
      createdAt
      updatedAt
      name
      slotDuration
      startTime
      endTime
      status
      limit
      current
      day
      __typename
    }
    nextSession {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    perviousSession {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    currentSessionNumber
    conversation {
      createdAt
      updatedAt
      id
      name
      isActive
      __typename
    }
    isActive
    __typename
  }
}
` as GeneratedQuery<APITypes.GroupQueryVariables, APITypes.GroupQuery>;
export const filteredMemberships = /* GraphQL */ `query FilteredMemberships(
  $programId: String
  $levelId: String
  $typeGroup: DivisionType
) {
  filteredMemberships(
    programId: $programId
    levelId: $levelId
    typeGroup: $typeGroup
  ) {
    id
    createdAt
    updatedAt
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      __typename
    }
    package {
      id
      createdAt
      updatedAt
      number
      onlyAI
      name
      name_i18n
      sessionDuration
      description
      benefits
      benefits_i18n
      details
      details_i18n
      isFree
      numberOfSessions
      description_i18n
      isNative
      isActive
      isHidden
      productIAP
      typePackage
      packageDuration
      discountDescription
      isRecommended
      sessionPerWeek
      __typename
    }
    curriculum {
      id
      createdAt
      updatedAt
      name
      totalPlanPeriod
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    paymentStatus
    placementStatus
    isActive
    startDate
    endDate
    program {
      id
      createdAt
      updatedAt
      name
      name_i18n
      flag
      supportAi
      isActive
      __typename
    }
    sessions {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    sessionPerWeek
    isFullFilled
    groups {
      id
      createdAt
      updatedAt
      startDate
      name
      division
      limit
      isGroupHasInstructor
      currentSessionNumber
      isActive
      __typename
    }
    discountcode {
      id
      createdAt
      updatedAt
      codeName
      valuePercentage
      isExpired
      validFrom
      expirationDate
      usage
      numOfUsage
      partnerName
      __typename
    }
    promocode {
      id
      createdAt
      updatedAt
      codeName
      isApplied
      sessionPerWeek
      __typename
    }
    studentaisessions {
      id
      createdAt
      updatedAt
      status
      currentQuestionIndex
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FilteredMembershipsQueryVariables,
  APITypes.FilteredMembershipsQuery
>;
export const groups = /* GraphQL */ `query Groups($filter: GroupsFilter, $scannedCount: Int, $nextToken: String) {
  groups(filter: $filter, scannedCount: $scannedCount, nextToken: $nextToken) {
    items {
      id
      createdAt
      updatedAt
      startDate
      name
      division
      limit
      isGroupHasInstructor
      currentSessionNumber
      isActive
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<APITypes.GroupsQueryVariables, APITypes.GroupsQuery>;
export const instructor = /* GraphQL */ `query Instructor($id: String!) {
  instructor(id: $id) {
    id
    createdAt
    updatedAt
    user {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      __typename
    }
    name
    gender
    program {
      id
      createdAt
      updatedAt
      name
      name_i18n
      flag
      supportAi
      isActive
      __typename
    }
    promo {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    sessionsCount
    speakingLanguages
    availabilities {
      id
      createdAt
      updatedAt
      startTime
      endTime
      day
      __typename
    }
    slots {
      id
      createdAt
      updatedAt
      name
      slotDuration
      startTime
      endTime
      status
      limit
      current
      day
      __typename
    }
    sessions {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    isNative
    totalRating
    totalReviews
    paymentMethod
    rating
    isActive
    status
    birthDate
    totalRatingConcentration
    totalRatingCamera
    totalRatingInternet
    totalRatingSessionQuality
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InstructorQueryVariables,
  APITypes.InstructorQuery
>;
export const instructors = /* GraphQL */ `query Instructors(
  $filter: InstructorsFilter
  $scannedCount: Int
  $nextToken: String
) {
  instructors(
    filter: $filter
    scannedCount: $scannedCount
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InstructorsQueryVariables,
  APITypes.InstructorsQuery
>;
export const preInstructor = /* GraphQL */ `query PreInstructor($id: String!) {
  preInstructor(id: $id) {
    id
    createdAt
    updatedAt
    user {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      __typename
    }
    name
    gender
    program {
      id
      createdAt
      updatedAt
      name
      name_i18n
      flag
      supportAi
      isActive
      __typename
    }
    promo {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    rating
    sessionsCount
    speakingLanguages
    availabilities {
      id
      createdAt
      updatedAt
      startTime
      endTime
      day
      __typename
    }
    slots {
      id
      createdAt
      updatedAt
      name
      slotDuration
      startTime
      endTime
      status
      limit
      current
      day
      __typename
    }
    sessions {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    isNative
    totalRating
    totalReviews
    isActive
    status
    birthDate
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PreInstructorQueryVariables,
  APITypes.PreInstructorQuery
>;
export const preInstructors = /* GraphQL */ `query PreInstructors(
  $filter: InstructorsFilter
  $scannedCount: Int
  $nextToken: String
) {
  preInstructors(
    filter: $filter
    scannedCount: $scannedCount
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      updatedAt
      name
      gender
      rating
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      isActive
      status
      birthDate
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PreInstructorsQueryVariables,
  APITypes.PreInstructorsQuery
>;
export const language = /* GraphQL */ `query Language($id: String!) {
  language(id: $id) {
    id
    createdAt
    updatedAt
    language
    country
    __typename
  }
}
` as GeneratedQuery<APITypes.LanguageQueryVariables, APITypes.LanguageQuery>;
export const languages = /* GraphQL */ `query Languages(
  $filter: LanguagesFilter
  $scannedCount: Int
  $nextToken: String
) {
  languages(
    filter: $filter
    scannedCount: $scannedCount
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      updatedAt
      language
      country
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<APITypes.LanguagesQueryVariables, APITypes.LanguagesQuery>;
export const lesson = /* GraphQL */ `query Lesson($id: String!) {
  lesson(id: $id) {
    id
    createdAt
    updatedAt
    name
    summary {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    lessonPDF {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    instructionsPDF {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    tests {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    course {
      id
      createdAt
      updatedAt
      name
      plan
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.LessonQueryVariables, APITypes.LessonQuery>;
export const lessonsByProgram = /* GraphQL */ `query LessonsByProgram($programId: String) {
  lessonsByProgram(programId: $programId) {
    id
    createdAt
    updatedAt
    name
    summary {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    lessonPDF {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    instructionsPDF {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    tests {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    course {
      id
      createdAt
      updatedAt
      name
      plan
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LessonsByProgramQueryVariables,
  APITypes.LessonsByProgramQuery
>;
export const lessons = /* GraphQL */ `query Lessons($filter: LessonsFilter, $scannedCount: Int, $nextToken: String) {
  lessons(filter: $filter, scannedCount: $scannedCount, nextToken: $nextToken) {
    items {
      id
      createdAt
      updatedAt
      name
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<APITypes.LessonsQueryVariables, APITypes.LessonsQuery>;
export const level = /* GraphQL */ `query Level($id: String!) {
  level(id: $id) {
    id
    createdAt
    updatedAt
    name
    number
    score
    __typename
  }
}
` as GeneratedQuery<APITypes.LevelQueryVariables, APITypes.LevelQuery>;
export const levels = /* GraphQL */ `query Levels($filter: LevelsFilter, $scannedCount: Int, $nextToken: String) {
  levels(filter: $filter, scannedCount: $scannedCount, nextToken: $nextToken) {
    items {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<APITypes.LevelsQueryVariables, APITypes.LevelsQuery>;
export const asset = /* GraphQL */ `query Asset($id: String!) {
  asset(id: $id) {
    id
    createdAt
    updatedAt
    type
    thumbnailUri
    fileUri
    title_i18n
    subTitle_i18n
    position
    __typename
  }
}
` as GeneratedQuery<APITypes.AssetQueryVariables, APITypes.AssetQuery>;
export const membership = /* GraphQL */ `query Membership($id: String!) {
  membership(id: $id) {
    id
    createdAt
    updatedAt
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      __typename
    }
    package {
      id
      createdAt
      updatedAt
      number
      onlyAI
      name
      name_i18n
      sessionDuration
      description
      benefits
      benefits_i18n
      details
      details_i18n
      isFree
      numberOfSessions
      description_i18n
      isNative
      isActive
      isHidden
      productIAP
      typePackage
      packageDuration
      discountDescription
      isRecommended
      sessionPerWeek
      __typename
    }
    curriculum {
      id
      createdAt
      updatedAt
      name
      totalPlanPeriod
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    paymentStatus
    placementStatus
    isActive
    startDate
    endDate
    program {
      id
      createdAt
      updatedAt
      name
      name_i18n
      flag
      supportAi
      isActive
      __typename
    }
    sessions {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    sessionPerWeek
    isFullFilled
    groups {
      id
      createdAt
      updatedAt
      startDate
      name
      division
      limit
      isGroupHasInstructor
      currentSessionNumber
      isActive
      __typename
    }
    discountcode {
      id
      createdAt
      updatedAt
      codeName
      valuePercentage
      isExpired
      validFrom
      expirationDate
      usage
      numOfUsage
      partnerName
      __typename
    }
    promocode {
      id
      createdAt
      updatedAt
      codeName
      isApplied
      sessionPerWeek
      __typename
    }
    studentaisessions {
      id
      createdAt
      updatedAt
      status
      currentQuestionIndex
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MembershipQueryVariables,
  APITypes.MembershipQuery
>;
export const memberships = /* GraphQL */ `query Memberships(
  $filter: MembershipsFilter
  $scannedCount: Int
  $nextToken: String
) {
  memberships(
    filter: $filter
    scannedCount: $scannedCount
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      updatedAt
      paymentStatus
      placementStatus
      isActive
      startDate
      endDate
      sessionPerWeek
      isFullFilled
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MembershipsQueryVariables,
  APITypes.MembershipsQuery
>;
export const parent = /* GraphQL */ `query Parent($id: String!) {
  parent(id: $id) {
    id
    createdAt
    updatedAt
    user {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      __typename
    }
    name
    gender
    __typename
  }
}
` as GeneratedQuery<APITypes.ParentQueryVariables, APITypes.ParentQuery>;
export const parents = /* GraphQL */ `query Parents($filter: ParentsFilter, $scannedCount: Int, $nextToken: String) {
  parents(filter: $filter, scannedCount: $scannedCount, nextToken: $nextToken) {
    items {
      id
      createdAt
      updatedAt
      name
      gender
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<APITypes.ParentsQueryVariables, APITypes.ParentsQuery>;
export const packagee = /* GraphQL */ `query Package($id: String!) {
  package(id: $id) {
    id
    createdAt
    updatedAt
    number
    onlyAI
    name
    name_i18n
    sessionDuration
    program {
      id
      createdAt
      updatedAt
      name
      name_i18n
      flag
      supportAi
      isActive
      __typename
    }
    price {
      id
      createdAt
      updatedAt
      name
      price
      currency
      country
      __typename
    }
    description
    benefits
    benefits_i18n
    promoAsset {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    coverAsset {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    activities {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    records {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    details
    details_i18n
    isFree
    numberOfSessions
    description_i18n
    isNative
    isActive
    isHidden
    enrollmentPackage {
      id
      createdAt
      updatedAt
      number
      onlyAI
      name
      name_i18n
      sessionDuration
      description
      benefits
      benefits_i18n
      details
      details_i18n
      isFree
      numberOfSessions
      description_i18n
      isNative
      isActive
      isHidden
      productIAP
      typePackage
      packageDuration
      discountDescription
      isRecommended
      sessionPerWeek
      __typename
    }
    promocodes {
      id
      createdAt
      updatedAt
      codeName
      isApplied
      sessionPerWeek
      __typename
    }
    productIAP
    typePackage
    packageDuration
    discountDescription
    isRecommended
    sessionPerWeek
    __typename
  }
}
` as GeneratedQuery<APITypes.PackageQueryVariables, APITypes.PackageQuery>;
export const packages = /* GraphQL */ `query Packages(
  $filter: PackagesFilter
  $scannedCount: Int
  $nextToken: String
) {
  packages(
    filter: $filter
    scannedCount: $scannedCount
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      updatedAt
      number
      onlyAI
      name
      name_i18n
      sessionDuration
      description
      benefits
      benefits_i18n
      details
      details_i18n
      isFree
      numberOfSessions
      description_i18n
      isNative
      isActive
      isHidden
      productIAP
      typePackage
      packageDuration
      discountDescription
      isRecommended
      sessionPerWeek
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<APITypes.PackagesQueryVariables, APITypes.PackagesQuery>;
export const getPlan = /* GraphQL */ `query GetPlan($id: String!) {
  getPlan(id: $id)
}
` as GeneratedQuery<APITypes.GetPlanQueryVariables, APITypes.GetPlanQuery>;
export const price = /* GraphQL */ `query Price($id: String!) {
  price(id: $id) {
    id
    createdAt
    updatedAt
    package {
      id
      createdAt
      updatedAt
      number
      onlyAI
      name
      name_i18n
      sessionDuration
      description
      benefits
      benefits_i18n
      details
      details_i18n
      isFree
      numberOfSessions
      description_i18n
      isNative
      isActive
      isHidden
      productIAP
      typePackage
      packageDuration
      discountDescription
      isRecommended
      sessionPerWeek
      __typename
    }
    name
    price
    currency
    country
    __typename
  }
}
` as GeneratedQuery<APITypes.PriceQueryVariables, APITypes.PriceQuery>;
export const prices = /* GraphQL */ `query Prices($filter: PricesFilter, $scannedCount: Int, $nextToken: String) {
  prices(filter: $filter, scannedCount: $scannedCount, nextToken: $nextToken) {
    items {
      id
      createdAt
      updatedAt
      name
      price
      currency
      country
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<APITypes.PricesQueryVariables, APITypes.PricesQuery>;
export const program = /* GraphQL */ `query Program($id: String!) {
  program(id: $id) {
    id
    createdAt
    updatedAt
    name
    name_i18n
    packages {
      id
      createdAt
      updatedAt
      number
      onlyAI
      name
      name_i18n
      sessionDuration
      description
      benefits
      benefits_i18n
      details
      details_i18n
      isFree
      numberOfSessions
      description_i18n
      isNative
      isActive
      isHidden
      productIAP
      typePackage
      packageDuration
      discountDescription
      isRecommended
      sessionPerWeek
      __typename
    }
    test {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    courses {
      id
      createdAt
      updatedAt
      name
      plan
      __typename
    }
    activities {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    records {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    flag
    supportAi
    isActive
    instructors {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.ProgramQueryVariables, APITypes.ProgramQuery>;
export const programs = /* GraphQL */ `query Programs($filter: ProgramsFilter, $limit: Int, $nextToken: String) {
  programs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      updatedAt
      name
      name_i18n
      flag
      supportAi
      isActive
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<APITypes.ProgramsQueryVariables, APITypes.ProgramsQuery>;
export const question = /* GraphQL */ `query Question($id: String!) {
  question(id: $id) {
    id
    createdAt
    updatedAt
    name
    name_i18n
    correctAsset {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    asset {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    videoAISessionUri
    choices
    customChoices
    programType
    keywords
    summary
    summary_i18n
    description
    description_i18n
    text
    textAI
    type
    __typename
  }
}
` as GeneratedQuery<APITypes.QuestionQueryVariables, APITypes.QuestionQuery>;
export const questions = /* GraphQL */ `query Questions(
  $filter: QuestionsFilter
  $scannedCount: Int
  $nextToken: String
) {
  questions(
    filter: $filter
    scannedCount: $scannedCount
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      updatedAt
      name
      name_i18n
      videoAISessionUri
      choices
      customChoices
      programType
      keywords
      summary
      summary_i18n
      description
      description_i18n
      text
      textAI
      type
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<APITypes.QuestionsQueryVariables, APITypes.QuestionsQuery>;
export const school = /* GraphQL */ `query School($id: String!) {
  school(id: $id) {
    id
    createdAt
    updatedAt
    nameArabic
    name
    students {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      __typename
    }
    teachers {
      id
      createdAt
      updatedAt
      name
      userName
      email
      __typename
    }
    user {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      __typename
    }
    isActive
    classesschool {
      id
      createdAt
      updatedAt
      name
      schoolYear
      __typename
    }
    promo {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.SchoolQueryVariables, APITypes.SchoolQuery>;
export const schools = /* GraphQL */ `query Schools($filter: SchoolsFilter, $scannedCount: Int, $nextToken: String) {
  schools(filter: $filter, scannedCount: $scannedCount, nextToken: $nextToken) {
    items {
      id
      createdAt
      updatedAt
      nameArabic
      name
      isActive
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<APITypes.SchoolsQueryVariables, APITypes.SchoolsQuery>;
export const session = /* GraphQL */ `query Session($id: String!) {
  session(id: $id) {
    id
    createdAt
    updatedAt
    name
    slot {
      id
      createdAt
      updatedAt
      name
      slotDuration
      startTime
      endTime
      status
      limit
      current
      day
      __typename
    }
    duration
    startDateTime
    startTime
    endTime
    status
    isRescheduled
    instructor {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    lesson {
      id
      createdAt
      updatedAt
      name
      __typename
    }
    moderatorToken
    jitsiUrl
    rtcUserToken
    rtcInstructorToken
    rtcShareScreenToken
    rtcAdminToken
    zoomUrl
    group {
      id
      createdAt
      updatedAt
      startDate
      name
      division
      limit
      isGroupHasInstructor
      currentSessionNumber
      isActive
      __typename
    }
    memberships {
      id
      createdAt
      updatedAt
      paymentStatus
      placementStatus
      isActive
      startDate
      endDate
      sessionPerWeek
      isFullFilled
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.SessionQueryVariables, APITypes.SessionQuery>;
export const sessions = /* GraphQL */ `query Sessions(
  $filter: SessionsFilter
  $scannedCount: Int
  $nextToken: String
) {
  sessions(
    filter: $filter
    scannedCount: $scannedCount
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<APITypes.SessionsQueryVariables, APITypes.SessionsQuery>;
export const slot = /* GraphQL */ `query Slot($id: String!) {
  slot(id: $id) {
    id
    createdAt
    updatedAt
    name
    slotDuration
    availability {
      id
      createdAt
      updatedAt
      startTime
      endTime
      day
      __typename
    }
    program {
      id
      createdAt
      updatedAt
      name
      name_i18n
      flag
      supportAi
      isActive
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    startTime
    endTime
    status
    limit
    current
    day
    group {
      id
      createdAt
      updatedAt
      startDate
      name
      division
      limit
      isGroupHasInstructor
      currentSessionNumber
      isActive
      __typename
    }
    instructor {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.SlotQueryVariables, APITypes.SlotQuery>;
export const slots = /* GraphQL */ `query Slots($filter: SlotsFilter, $scannedCount: Int, $nextToken: String) {
  slots(filter: $filter, scannedCount: $scannedCount, nextToken: $nextToken) {
    items {
      id
      createdAt
      updatedAt
      name
      slotDuration
      startTime
      endTime
      status
      limit
      current
      day
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<APITypes.SlotsQueryVariables, APITypes.SlotsQuery>;
export const student = /* GraphQL */ `query Student($id: String!) {
  student(id: $id) {
    id
    createdAt
    updatedAt
    user {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      __typename
    }
    schoolName
    name
    birthDate
    age
    targetAge
    gender
    language {
      id
      createdAt
      updatedAt
      language
      country
      __typename
    }
    notes
    facebookProfileUrl
    enrollments {
      id
      createdAt
      updatedAt
      placementStatus
      paymentStatus
      startDate
      endDate
      selfRating
      __typename
    }
    memberships {
      id
      createdAt
      updatedAt
      paymentStatus
      placementStatus
      isActive
      startDate
      endDate
      sessionPerWeek
      isFullFilled
      __typename
    }
    statistic {
      id
      createdAt
      updatedAt
      scores
      averageScore
      fullReportLastGeneratedDate
      phoneticsAsString
      syllablesAsString
      __typename
    }
    studentaudioprofile {
      id
      createdAt
      updatedAt
      introAIVSUri
      sentenceIntro
      correctAIVSUri
      sentenceCorrect
      failAIVSUri
      sentenceFail
      finalAIVSUri
      sentenceFinal
      studentName
      __typename
    }
    status
    character
    assignedTo
    sessions {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    studentaisessions {
      id
      createdAt
      updatedAt
      status
      currentQuestionIndex
      __typename
    }
    studentconversations {
      createdAt
      updatedAt
      id
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    school {
      id
      createdAt
      updatedAt
      nameArabic
      name
      isActive
      __typename
    }
    classschool {
      id
      createdAt
      updatedAt
      name
      schoolYear
      __typename
    }
    isSchoolVerfied
    placementStatus
    email
    userName
    totalRatingConcentration
    totalRatingCamera
    totalRatingInternet
    totalRatingSessionQuality
    totalRating
    totalReviews
    __typename
  }
}
` as GeneratedQuery<APITypes.StudentQueryVariables, APITypes.StudentQuery>;
export const students = /* GraphQL */ `query Students(
  $filter: StudentsFilter
  $scannedCount: Int
  $nextToken: String
) {
  students(
    filter: $filter
    scannedCount: $scannedCount
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<APITypes.StudentsQueryVariables, APITypes.StudentsQuery>;
export const statistic = /* GraphQL */ `query Statistic($id: String!) {
  statistic(id: $id) {
    id
    createdAt
    updatedAt
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      __typename
    }
    scores
    averageScore
    fullReportLastGeneratedDate
    monthlyReports {
      id
      createdAt
      updatedAt
      month
      average
      mistakes
      attempts
      totalScores
      totalAverage
      absenceDays
      performanceRate
      absenceRate
      numOfStudentsInClass
      d1
      d2
      d3
      d4
      d5
      d6
      d7
      d8
      d9
      d10
      d11
      d12
      d13
      d14
      d15
      d16
      d17
      d18
      d19
      d20
      d21
      d22
      d23
      d24
      d25
      d26
      d27
      d28
      d29
      d30
      d31
      __typename
    }
    classschool {
      id
      createdAt
      updatedAt
      name
      schoolYear
      __typename
    }
    phoneticsAsString
    syllablesAsString
    __typename
  }
}
` as GeneratedQuery<APITypes.StatisticQueryVariables, APITypes.StatisticQuery>;
export const statistics = /* GraphQL */ `query Statistics(
  $filter: StatisticsFilter
  $scannedCount: Int
  $nextToken: String
) {
  statistics(
    filter: $filter
    scannedCount: $scannedCount
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      updatedAt
      scores
      averageScore
      fullReportLastGeneratedDate
      phoneticsAsString
      syllablesAsString
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.StatisticsQueryVariables,
  APITypes.StatisticsQuery
>;
export const monthlyReport = /* GraphQL */ `query MonthlyReport($id: String!) {
  monthlyReport(id: $id) {
    id
    createdAt
    updatedAt
    month
    average
    mistakes
    attempts
    statistic {
      id
      createdAt
      updatedAt
      scores
      averageScore
      fullReportLastGeneratedDate
      phoneticsAsString
      syllablesAsString
      __typename
    }
    totalScores
    totalAverage
    absenceDays
    performanceRate
    absenceRate
    numOfStudentsInClass
    d1
    d2
    d3
    d4
    d5
    d6
    d7
    d8
    d9
    d10
    d11
    d12
    d13
    d14
    d15
    d16
    d17
    d18
    d19
    d20
    d21
    d22
    d23
    d24
    d25
    d26
    d27
    d28
    d29
    d30
    d31
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MonthlyReportQueryVariables,
  APITypes.MonthlyReportQuery
>;
export const monthlyReports = /* GraphQL */ `query MonthlyReports(
  $filter: MonthlyReportsFilter
  $scannedCount: Int
  $nextToken: String
) {
  monthlyReports(
    filter: $filter
    scannedCount: $scannedCount
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      updatedAt
      month
      average
      mistakes
      attempts
      totalScores
      totalAverage
      absenceDays
      performanceRate
      absenceRate
      numOfStudentsInClass
      d1
      d2
      d3
      d4
      d5
      d6
      d7
      d8
      d9
      d10
      d11
      d12
      d13
      d14
      d15
      d16
      d17
      d18
      d19
      d20
      d21
      d22
      d23
      d24
      d25
      d26
      d27
      d28
      d29
      d30
      d31
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MonthlyReportsQueryVariables,
  APITypes.MonthlyReportsQuery
>;
export const test = /* GraphQL */ `query Test($id: String!) {
  test(id: $id) {
    id
    createdAt
    updatedAt
    questions {
      id
      createdAt
      updatedAt
      name
      name_i18n
      videoAISessionUri
      choices
      customChoices
      programType
      keywords
      summary
      summary_i18n
      description
      description_i18n
      text
      textAI
      type
      __typename
    }
    type
    name
    name_i18n
    description
    description_i18n
    summary
    summary_i18n
    asset {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    lesson {
      id
      createdAt
      updatedAt
      name
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.TestQueryVariables, APITypes.TestQuery>;
export const tests = /* GraphQL */ `query Tests($filter: TestsFilter, $scannedCount: Int, $nextToken: String) {
  tests(filter: $filter, scannedCount: $scannedCount, nextToken: $nextToken) {
    items {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<APITypes.TestsQueryVariables, APITypes.TestsQuery>;
export const getUserByCognitoId = /* GraphQL */ `query GetUserByCognitoId($id: String!) {
  getUserByCognitoId(id: $id) {
    id
    createdAt
    updatedAt
    cognitoUserId
    country
    state
    whatsappNumber
    timeZone
    firstName
    lastName
    parent {
      id
      createdAt
      updatedAt
      name
      gender
      __typename
    }
    admin {
      id
      createdAt
      updatedAt
      lastSeenOnline
      firstName
      lastName
      address
      SSN
      gender
      isActive
      __typename
    }
    instructor {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    students {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      __typename
    }
    school {
      id
      createdAt
      updatedAt
      nameArabic
      name
      isActive
      __typename
    }
    teacher {
      id
      createdAt
      updatedAt
      name
      userName
      email
      __typename
    }
    userconversations {
      createdAt
      updatedAt
      id
      __typename
    }
    deviceToken
    devicePlatform
    endPointArn
    email
    contactIDActiveCampaign
    partnerAppName
    preInstructor {
      id
      createdAt
      updatedAt
      name
      gender
      rating
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      isActive
      status
      birthDate
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserByCognitoIdQueryVariables,
  APITypes.GetUserByCognitoIdQuery
>;
export const user = /* GraphQL */ `query User($id: String!) {
  user(id: $id) {
    id
    createdAt
    updatedAt
    cognitoUserId
    country
    state
    whatsappNumber
    timeZone
    firstName
    lastName
    parent {
      id
      createdAt
      updatedAt
      name
      gender
      __typename
    }
    admin {
      id
      createdAt
      updatedAt
      lastSeenOnline
      firstName
      lastName
      address
      SSN
      gender
      isActive
      __typename
    }
    instructor {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    students {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      __typename
    }
    school {
      id
      createdAt
      updatedAt
      nameArabic
      name
      isActive
      __typename
    }
    teacher {
      id
      createdAt
      updatedAt
      name
      userName
      email
      __typename
    }
    userconversations {
      createdAt
      updatedAt
      id
      __typename
    }
    deviceToken
    devicePlatform
    endPointArn
    email
    contactIDActiveCampaign
    partnerAppName
    preInstructor {
      id
      createdAt
      updatedAt
      name
      gender
      rating
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      isActive
      status
      birthDate
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.UserQueryVariables, APITypes.UserQuery>;
export const users = /* GraphQL */ `query Users($filter: UsersFilter, $scannedCount: Int, $nextToken: String) {
  users(filter: $filter, scannedCount: $scannedCount, nextToken: $nextToken) {
    items {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedQuery<APITypes.UsersQueryVariables, APITypes.UsersQuery>;
export const me = /* GraphQL */ `query Me($cognitoUserId: String) {
  me(cognitoUserId: $cognitoUserId) {
    id
    createdAt
    updatedAt
    cognitoUserId
    country
    state
    whatsappNumber
    timeZone
    firstName
    lastName
    parent {
      id
      createdAt
      updatedAt
      name
      gender
      __typename
    }
    admin {
      id
      createdAt
      updatedAt
      lastSeenOnline
      firstName
      lastName
      address
      SSN
      gender
      isActive
      __typename
    }
    instructor {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    students {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      __typename
    }
    school {
      id
      createdAt
      updatedAt
      nameArabic
      name
      isActive
      __typename
    }
    teacher {
      id
      createdAt
      updatedAt
      name
      userName
      email
      __typename
    }
    userconversations {
      createdAt
      updatedAt
      id
      __typename
    }
    deviceToken
    devicePlatform
    endPointArn
    email
    contactIDActiveCampaign
    partnerAppName
    preInstructor {
      id
      createdAt
      updatedAt
      name
      gender
      rating
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      isActive
      status
      birthDate
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.MeQueryVariables, APITypes.MeQuery>;
