export const preInstructor = /* GraphQL */ `
  query PreInstructor($id: String!) {
    preInstructor(id: $id) {
      id
      createdAt
      updatedAt
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        partnerAppName
      }
      name
      gender
      program {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
      }
      promo {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      rating
      sessionsCount
      speakingLanguages
      availabilities {
        id
        createdAt
        updatedAt
        startTime
        endTime
        day
      }
      slots {
        id
        createdAt
        updatedAt
        name
        slotDuration
        startTime
        endTime
        status
        limit
        current
        day
      }
      sessions {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        rtcUserToken
        rtcInstructorToken
        zoomUrl
      }
      isNative
      totalRating
      totalReviews
      isActive
      status
      birthDate
    }
  }
`;

export const preInstructors = /* GraphQL */ `
  query PreInstructors(
    $filter: InstructorsFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    preInstructors(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        name
        gender
        user {
          id
          createdAt
          updatedAt
          cognitoUserId
          country
          state
          whatsappNumber
          timeZone
          firstName
          lastName
          deviceToken
          devicePlatform
          endPointArn
          partnerAppName
        }
        promo {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
          title_i18n
          subTitle_i18n
          position
        }
        rating
        sessionsCount
        speakingLanguages
        program {
          id
          createdAt
          updatedAt
          name
          name_i18n
          flag
          supportAi
          isActive
        }
        isNative
        totalRating
        totalReviews
        isActive
        status
        birthDate
      }
      nextToken
      scannedCount
    }
  }
`;
