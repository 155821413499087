export const discountcodes = /* GraphQL */ `
  query Discountcodes(
    $filter: DiscountcodeFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    discountcodes(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        codeName
        valuePercentage
        isExpired
        validFrom
        expirationDate
        usage
        package {
          id
          name_i18n
          program {
            name
            id
            flag
          }
        }
        student {
          id
        }
      }
      nextToken
      scannedCount
    }
  }
`;

export const discountcodesForPartner = /* GraphQL */ `
  query Discountcodes(
    $filter: DiscountcodeFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    discountcodes(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        codeName
        valuePercentage
        isExpired
        validFrom
        expirationDate
        usage
        package {
          id
          name_i18n
          program {
            name
            id
            flag
          }
        }
        school {
          id
          name
        }
        level {
          id
          name
        }
        curriculum {
          id
          name
        }
      }
      nextToken
      scannedCount
    }
  }
`;

export const getSubscriptionsByDiscountCode = /* GraphQL */ `
  query GetSubscriptionsByDiscountCode($discountcodeId: String!) {
    getSubscriptionsByDiscountCode(discountcodeId: $discountcodeId) {
      numOfSubscriptions
      numOfUsage
      discountcode {
        id
        createdAt
        updatedAt
        codeName
        valuePercentage
        isExpired
        validFrom
        expirationDate
        usage
        numOfUsage
        partnerName
        __typename
      }
      school {
        id
        createdAt
        updatedAt
        nameArabic
        name
        isActive
        __typename
      }
      __typename
    }
  }
`;
